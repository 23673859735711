import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const GridLayout = ({ items, itemComponent: ItemComponent, namespace, preload }) => (
  <div className={classNames(`${namespace}__grid`)}>
    {items.map((item, index) => (
      <ItemComponent
        item={item}
        preload={preload && index < 3}
        key={item.id}
        className={classNames(`${namespace}__item`)}
      />
    ))}
  </div>
);

GridLayout.propTypes = {
  itemComponent: PropTypes.elementType,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  namespace: PropTypes.string,
  preload: PropTypes.bool,
};

export default GridLayout;
